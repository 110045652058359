.jobCardPage {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  padding: 35px;
  overflow-y: scroll;
}

.subJobs {
  flex: 1;
}

.jobDetails {
  display: flex;
  gap: 16px;
}

.left {
  flex: 0.8;
}

.right {
  flex: 1.2;
}

.width {
  max-width: 1512px;
}