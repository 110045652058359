.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContent {
  background-color: white;
  max-height: 90vh;
  width: 1500px;
  border-radius: 10px;
  box-shadow: 0px 4px 7.8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 90vh;
  overflow: hidden;
}

.modalContentMain {
  overflow-y: auto;
  flex: 1;
}

.modalHeader {
  height: 116px;
  padding: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
}

.jobCardText {
  font-size: 16px;
}

.jobCardNumber {
  font-size: 24px;
  font-weight: bold;
  font-family: "robotoBold";
}

.modalHeaderLeft {
  display: flex;
  gap: 40px;
}

.modalJobStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
  height: 27px;
  border-radius: 25px;
  background-color: rgb(12, 125, 255);
  margin-top: 20px;
}

.modalJobStatus p {
  font-size: 16px;
  font-family: "robotoBold";
  color: white;
}

.closeIcon {
  cursor: pointer;
}

.modalSubHeader {
  height: 70px;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 36px;
}

.modalSubHeaderLeft {
  display: flex;
  gap: 43px;
}

.modalSection {
  padding: 36px;
  height: auto;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
}

.completedSection {
  padding: 17px 36px;
  height: 54px;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  display: flex;
  align-items: center;
  gap: 15px;
}

.modalBodyLeft {
  width: 50%;
}

.modalBodyRight {
  width: 50%;
}

.infoContainer {
  display: flex;
  gap: 102px;
  margin-top: 36px;
}

.bold {
  font-weight: bold;
  font-family: "robotoBold";
}

.titleBold {
  font-weight: bold;
  font-size: 18px;
  font-family: "robotoBold";
}

.contactsContainer {
  display: flex;
  gap: 50px;
  margin-top: 36px;
}

.contactFlex {
  display: flex;
  align-items: center;
  gap: 6px;
}

.descriptionContainer {
  margin-top: 49px;
}

.descriptionBox {
  /* border: 1px solid rgba(228, 228, 228, 1); */
  /* border-radius: 10px; */
  /* padding: 25px; */
  margin-top: 14px;
  height: 218px;
}

.modalColumnSeparator {
  display: flex;
  gap: 20px;
}



.informationContainer {
  margin-top: 32px;
}

.infoBox {
  border-radius: 10px;
  margin-top: 14px;
  height: 218px;
}

.jobDetailsSection {
  display: flex;
  gap: 20px;
  background-color: rgba(244, 247, 249, 1);
  padding: 24px;
  border-radius: 16px;
}

.jobDetailsLeft,
.jobDetailsRight {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jobDetailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.jobDetailsFlexItem {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
  white-space: nowrap;
}

.visitContainer {
  margin-top: 42px;
  height: auto;
  /* padding-bottom: 36px;
  border-bottom: 1px solid rgba(234, 234, 234, 1); */
}

.visitTimeline {
  display: flex;
  gap: 6px;
}

.visitTimeLine1 {
  width: 100%;
  text-align: center;
}

.visitTimeLine2 {
  width: 100%;
  text-align: center;
}

.visitTimeLine3 {
  width: 100%;
  text-align: center;
}

.visitDates {
  font-size: 12px;
  margin-top: 15px;
}

.visitTimeLine1Hours,
.visitTimeLine2Hours,
.visitTimeLine3Hours {
  height: 26px;
  background-color: rgba(220, 240, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
}

.visitTimeLine1Status,
.visitTimeLine2Status,
.visitTimeLine3Status {
  height: 47px;
  background-color: rgba(0, 148, 255, 1);
  box-shadow: 0px 3px 2.8px 0px rgba(0, 0, 0, 0.1);
  padding: 7px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.visitTimeLine1Status img,
.visitTimeLine2Status img,
.visitTimeLine3Status img {
  display: flex;
  align-self: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 1);
}

.visitTimeLine1Status p,
.visitTimeLine2Status p,
.visitTimeLine3Status p {
  font-family: "robotoBold";
  object-fit: contain;
  font-size: 16px;
  color: white;
}

.visitTimeLine3Status {
  background-color: rgba(68, 201, 137, 1);
}

.visitBarContainer {
  display: flex;
  gap: 3.46px;
  margin-top: 16px;
}

.visitBar1 {
  height: 7px;
  background-color: rgba(197, 217, 232, 1);
  width: 100%;
}

.visitBar2 {
  height: 7px;
  width: 100%;
  background-color: rgba(255, 174, 60, 1);
  margin-bottom: 2px;
}

.visitBarContainer1,
.visitBarContainer2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.visitFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.greyCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(197, 217, 232, 1);
}

.orangeCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 174, 59, 1);
}

.completedInfo {
  display: flex;
  gap: 3px;
}

.completedSection img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.taskDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.taskDetailsBox {
  width: 100%;
  height: 137px;
  border-radius: 10px;
  border: 1px solid rgba(237, 237, 237, 1);
  box-shadow: 0px 9px 12px 0px rgba(232, 232, 232, 0.14);
  display: flex;
}

.workDone {
  width: 394px;
  border-right: 1px solid rgba(237, 237, 237, 1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.workToBeDone {
  width: 201px;
  border-right: 1px solid rgba(237, 237, 237, 1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.estimatedTime {
  width: 270px;
  border-right: 1px solid rgba(237, 237, 237, 1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.materialRequired {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.salePaymentsContainer {
  margin-top: 36px;
  display: flex;
  gap: 26px;
}

.saleContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex: 1;
}

.saleBox {
  width: 100%;
  height: 413px;
  border-radius: 10px;
  border: 1px solid rgba(237, 237, 237, 1);
  box-shadow: 0px 9px 12px 0px rgba(232, 232, 232, 0.14);
}

.paymentsBox {
  width: 463px;
  height: 158px;
  margin-top: 18px;
  border-radius: 10px;
  border: 1px solid rgba(237, 237, 237, 1);
  box-shadow: 0px 9px 12px 0px rgba(232, 232, 232, 0.14);
}

.paymentsTabs {
  display: flex;
  gap: 45px;
  margin-top: 33.5px;
  margin-left: 30px;
}

.paymentTab {
  display: flex;
  gap: 6px;
  align-items: center;
}

.greyNumber {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(241, 241, 241, 1);
}

.greyNumber p {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mediaDocumentsBox {
  width: 463px;
  height: 288px;
  box-shadow: 0px 9px 12px 0px rgba(232, 232, 232, 0.14);
  border-radius: 10px;
  border: 1px solid rgba(237, 237, 237, 1);
  margin-top: 18.5px;
  padding: 36px;
}

.paymentEntry {
  display: flex;
  align-items: center;
  padding: 24px 24px 16px 24px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(237, 237, 237, 1);
}

.paymentEntry:last-child {
  border-bottom: none;
}

.paymentEntryLeft {
  display: flex;
  align-items: center;
  gap: 24px;
}

.paymentEntryLeft img {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.videosContainer {
  margin-top: 16px;
  display: flex;
  gap: 10px;
  margin-bottom: 26px;
}

.videosContainer:last-child {
  margin-bottom: 0px;
}

.videosContainer img {
  height: 60px;
  width: 60px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.saleHeader {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  border-bottom: 1px solid rgba(237, 237, 237, 1);
}

.saleHeaderLeft {
  display: flex;
  align-items: center;
  gap: 19px;
}

.saleStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100px;
  height: 33px;
  background-color: rgba(227, 251, 228, 1);
  border-radius: 10px;
}

.saleStatus p {
  font-size: 16px;
  font-family: "robotoBold";
  color: rgba(72, 195, 56, 1);
}

.saleHeaderRight {
  display: flex;
  gap: 4px;
}

.saleDetails {
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.saleDetailsPerson {
  display: flex;
  align-items: center;
  gap: 7px;
}

.saleDetailsPerson img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.saleDetailsPerson p {
  text-decoration: underline;
}


.invoiceContainer {
  padding: 0px 24px 24px 24px;
}

.invoiceTable {
  width: 100%;
  border-collapse: collapse;
}

.invoiceTable th,
.invoiceTable td {
  padding: 10px;
  text-align: left;
}

.invoiceTable td {
  font-family: "robotoRegular";
  font-size: 16px;
}

.invoiceTable th {
  font-family: "robotoBold";
  font-size: 16px;
}

.invoiceTable thead {
  background-color: #f3f6f9;
  border-radius: 10px;
  font-weight: bold;
}

.invoiceTable tbody tr {
  border-bottom: 1px solid #eaeaea;
}


.invoiceTable tbody tr:last-child td {
  border-bottom: none;
}

.invoiceTable tbody tr td:last-child {
  text-align: right;
}

.invoiceTable thead tr:first-child th:first-child {
  border-radius: 10px 0 0 10px;
}

.invoiceTable thead tr:first-child th:last-child {
  border-radius: 0 10px 10px 0;
}

.discountTotalContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
  margin-right: 9px;
}

.discountLabel {
  display: flex;
  gap: 70px;
}

.totalLabel {
  display: flex;
  gap: 70px;
}

.modalFooter {
  display: flex;
  height: 88px;
  justify-content: space-between;
  padding: 24px 36px;
  align-items: center;
}

.selectedTab,
.unselectedTab {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedTab {
  border-bottom: 2px solid rgba(0, 148, 255, 1);
}

.jobTypes_list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.job_types {
  background-color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
}